<template>
  <v-alert
      :value="alert"
      @input="alert = $event"
      :dismissible="dismissible"
      :type="type"
      :transition="transition"
      class="alert-banner"
  >
    <ul>
      <li v-for="message in messages" :key="message" v-html="message"/>
    </ul>
  </v-alert>
</template>
<style scoped>
.alert-banner {
  position: fixed;
  top: 64px;
  right: 16px;
  z-index: 9999;
}
</style>
<script>
export default {
  name: "AlertBanner",
  props: {
    value: String,
    type: {
      type: String,
      default: "error",
    },
    transition: {
      type: String,
      default: "slide-x-reverse-transition",
    },
    dismissible: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    alert: {
      get() {
        return !!this.value;
      },
      set() {
        this.$emit('input', '');
      },
    },
    messages() {
      return Array.isArray(this.value) ? this.value : [this.value];
    },
  },
};
</script>
