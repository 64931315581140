export default {
    locale: "ru",
    api: {
        facility: {
            facility: "/facilities",
            details: "/facilities/details",
        },
        profile: {
            login: "/login",
            admin: "/login/admin",
            token: "/login/token",
            logout: "/logout",
            register: "/register",
            forgot: "/register/forgot",
            reset: "/password/reset",
            restore: "/register/restore",
            lead: "/users/lead",
            me: "/users/me",
            units: "/users/me/units",
            invoices: "/users/me/invoices",
            documents: "/users/me/documents",
            subscriptions: "/users/me/subscriptions",
            pin: "/users/me/pincode",
            customInvoice: "/users/me/custom-invoice",
            rentRequest: "/users/me/rent-request",
            password: "/users/me/password",
            price: "/price",
            checkout: "/checkout",
            applePayValidate: "/pay/apple-pay/validate",
        },
        payment: {
            pay: "/pay/invoice",
        },
        admin: {
            subscriptions: "/admin/subscriptions",
            users: "/admin/users",
            documents: "/admin/documents",
            pages: "/admin/pages",
        },
    },
};
