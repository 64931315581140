<template>
  <v-menu
      v-if="$vuetify.breakpoint.mdAndUp"
      :offset-y="offsetY"
      :min-width="minWidth"
      :max-width="maxWidth"
      :transition="transition"
      v-model="value"
  >
    <template #activator="{ on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs"/>
    </template>
    <slot>
      <v-list>
        <v-list-item
            v-for="(item, idx) in list"
            :key="idx"
            link
        >
          <v-list-item-title @click="select(item.code)">{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
  <v-dialog
      v-else
      v-model="value"
  >
    <template #activator="{ on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs"/>
    </template>
    <slot>
      <v-list>
        <v-list-item
            v-for="(item, idx) in list"
            :key="idx"
            link
        >
          <v-list-item-title @click="select(item.code)">{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </slot>
  </v-dialog>
</template>

<script>
export default {
  name: "PrMenu",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    offsetY: Boolean,
    minWidth: Number,
    maxWidth: Number,
    transition: String,
    list: Array,
  },
  methods: {
    select(code) {
      this.$emit('input', false);
      this.$emit('change', code);
    }
  },
};
</script>

<style scoped>

</style>
