<template>
  <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      :max-width="$vuetify.breakpoint.xsOnly ? 'auto' : maxWidth"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'"
      :content-class="`base-dialog ${noFull ? 'no-fullscreen' : 'fullscreen'} ${$vuetify.breakpoint.xsOnly ? 'mobile' : ''}`"
  >
    <template #activator="{ on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <slot></slot>
  </v-dialog>
</template>
<style>
.base-dialog.mobile {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  overflow-y: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  transition-duration: .5s;
}

.base-dialog {
  display: flex;
}

.base-dialog.mobile.fullscreen {
  height: 95% !important;
  max-height: 95% !important;
}
</style>
<script>

export default {
  name: "BaseDialog",
  props: {
    value: Boolean,
    noFull: Boolean,
    maxWidth: Number,
    activator: Object,
  },
};
</script>
