import axios from "axios";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND,
    headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
    },
});

instance.interceptors.request.use(function (config) {
    let token = localStorage.getItem("token_auth")
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

instance.interceptors.response.use(null, function (error) {
    if (error.response.status === 401) {
        document.dispatchEvent(new Event('user-unauth'));
    }

    let message;
    if (error.response.data.errors) {
        message = Object.keys(error.response.data.errors)
            .reduce((errors, key) => errors.concat(error.response.data.errors[key]), []);
    }
    error.response.data.message = message || error.response.data.message;

    return Promise.reject(error);
});
instance.defaults.withCredentials = true;

export default instance;
