import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import profile from "./profile";
import admin from "./admin";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        profile,
        admin,
    },
});

export default store;
