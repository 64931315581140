<template>
  <v-form ref="form" @submit.stop.prevent="getPrice">
    <pr-select
        v-model="facilityCode"
        label="Адрес склада"
        :items="facilities"
        item-value="facility_code"
        item-text="name"
        @change="changeFacility"
        :rules="[v => !!v || 'Обязательное поле']"
        required
    />
    <pr-select
        v-model="unitType"
        label="Размер бокса"
        no-data-text="Выберите склад"
        :items="unitTypes"
        item-value="unit_type"
        item-text="name"
        @change="changeUnitType"
        required
        :rules="[v => !!v || 'Обязательное поле']"
    >
      <template #item="{item, on}">
        <div class="d-flex flex-column flex-grow-1" v-on="on">
          <div v-html="item.name"/>
          <small class="text--secondary">{{ `${format.toCurrency(item.price)}/мес.` }}</small>
          <v-divider/>
        </div>
      </template>
      <template #selection="{item}">
        <div v-html="item.name"/>
        <small class="px-2 text--secondary">{{ `${format.toCurrency(item.price)}/мес.` }}</small>
      </template>
    </pr-select>
    <div class="d-flex flex-column flex-sm-row">
      <pr-menu
          v-model="fromMenu"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              readonly
              v-model="humanDateFrom"
              class="col-12 col-sm-6 date-field px-0"
              label="Период c"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              :rules="[v => !!dateFrom || 'Обязательное поле']"
              required
          />
        </template>
        <v-date-picker
            :first-day-of-week="1"
            locale="ru"
            v-model="dateFrom"
            @change="getPrice"
            no-title
            color="primary"
            :min="today"
            :max="oneWeekLater"
        />
      </pr-menu>
      <pr-select
          v-model="period"
          :items="periods"
          label="На срок"
          item-text="title"
          item-value="period"
          class="col-12 col-sm-6 date-field px-0"
          @change="getPrice"
          required
          :rules="[v => !!v || 'Обязательное поле']"
      />
    </div>
    <v-text-field
        class="col-12 col-sm-8 col-md-6 px-0"
        v-model="promo"
        label="Промокод"
        @input="invalidPromo = false; validate()"
        :rules="[v => !invalidPromo || !promo || 'Промокод не найден']"
        autocomplete="off"
    >
      <template v-slot:append-outer>
        <v-btn text color="primary" @click="getPrice" :disabled="!valid" small type="submit">Проверить</v-btn>
      </template>
    </v-text-field>
    <div class="d-flex">
      <v-btn
          @click="next"
          text
          color="primary"
          :disabled="!valid"
          :loading="loading"
          class="px-0"
      >
        Далее >>
      </v-btn>
      <v-spacer/>
      <div class="d-flex align-center">
        <div v-if="value.completed" class="d-flex px-2 py-0 flex-column flex-sm-row align-baseline">
          <span v-if="value.price !== value.amount" class="old-price text-decoration-line-through pr-2">{{ format.toNumber(value.price) }}</span>
          <span class="text-h6 text-sm-h5 primary--text">{{ format.toCurrency(value.amount) }}</span>
        </div>
      </div>
    </div>
    <alert-banner v-model="error"/>
    <v-dialog v-model="disclaimerDialog" :max-width="$vuetify.breakpoint.smAndDown ? '400px' : '600px'">
      <div class="d-flex">
        <v-card>
          <img v-if="$vuetify.breakpoint.smAndDown" :src="`${PROFILE_DOMAIN}/img/mezzanine-informer-mobile.jpg`" alt="Трап" class="mezzanine-informer-mobile"/>
          <v-card-title class="headline">
            Обратите внимание
          </v-card-title>
          <v-card-text>
            <p>Вы выбрали антресольный бокс на высоте 2 метра.</p>
            <p>Для удобства доступа на складе есть трапы и стремянки.</p>
          </v-card-text>
          <div class="py-3 px-6">
            <v-btn
                color="primary"
                outlined
                @click="disclaimerDialog = false">
              Хорошо
            </v-btn>
          </div>
        </v-card>
        <img v-if="!$vuetify.breakpoint.smAndDown" :src="`${PROFILE_DOMAIN}/img/mezzanine-informer.jpg`" alt="Трап" class="mezzanine-informer"/>
      </div>
    </v-dialog>
  </v-form>
</template>

<script>
import date from "@/lib/date";
import format from "@/lib/format";
import PrMenu from "@/components/PrMenu";
import PrSelect from "@/components/PrSelect";
import AlertBanner from "@/components/AlertBanner";
import FacilityRepository from "@/repository/facility";
import CustomerRepository from "@/repository/customer";

export default {
  name: "UnitSelector",
  components: {AlertBanner, PrSelect, PrMenu},
  props: {
    value: Object,
    initFacility: String,
    initUnit: String,
  },
  data() {
    return {
      facilities: [],
      facilityCode: null,
      freeUnitTypes: [],
      unitType: null,
      dateFrom: "",
      fromMenu: false,
      periods: [
        {
          title: "1 неделя",
          period: "1w",
        },
        {
          title: "1 месяц",
          period: "1m",
        },
        {
          title: "3 месяца",
          period: "3m",
        },
        {
          title: "6 месяцев",
          period: "6m",
        },
        {
          title: "11 месяцев",
          period: "11m",
        },
      ],
      promo: "",
      format,
      loading: false,
      error: "",
      disclaimerDialog: false,
      valid: false,
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
  async mounted() {
    this.facilities = await FacilityRepository.list();
    if (this.initFacility) {
      this.facilityCode = this.initFacility;
      await this.changeFacility();
      if (this.initUnit) {
        this.unitType = this.initUnit
        await this.changeUnitType();
      }
    }
  },
  computed: {
    unitTypes() {
      const facility = this.facilities.find(facility => facility.facility_code === this.facilityCode);
      if (facility) {
        const available = [];
        const unavailable = [];
        facility.unit_types.forEach(type => {
          if (this.freeUnitTypes.find(freeType => freeType.typeName === type.unit_type)) {
            type.disabled = false;
            available.push(type);
          } else {
            type.disabled = true;
            unavailable.push(type);
          }
        });
        if (unavailable.length) {
          unavailable.unshift(
              {header: "В данный момент недоступно", inset: true},
              {header: "Позвоните нам, чтобы встать в лист ожидания,", inset: true},
          );
        }
        return available.concat(unavailable);
      }
      return [];
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    oneWeekLater() {
      let today = new Date();
      today.setDate(today.getDate() + 7);
      return today.toISOString().substr(0, 10);
    },
    humanDateFrom() {
      return this.dateFrom ? date.isoToHuman(this.dateFrom) : null;
    },
  },
  methods: {
    async changeFacility() {
      this.freeUnitTypes = await FacilityRepository.free(this.facilityCode);
      this.unitType = null;
      await this.getPrice();
    },
    async changeUnitType() {
      if (this.unitType.indexOf("Ent") === 0) {
        this.disclaimerDialog = true;
      }
      await this.getPrice();
    },
    validate() {
      this.valid = this.facilityCode && this.unitType && this.dateFrom && this.period && this.$refs.form.validate();
      this.$emit('input', {completed: false});
      return this.valid;
    },
    async getPrice() {
      if (!this.validate()) {
        return;
      }
      this.loading = true;
      this.error = "";
      try {
        const response = await CustomerRepository.priceRequest({
          facilityCode: this.facilityCode,
          unitType: this.unitType,
          startDate: this.dateFrom,
          period: this.period,
          promoCode: this.promo,
          useInsurance: true,
        });
        this.$emit('input', {
          facilityCode: this.facilityCode,
          unitType: this.unitType,
          unitTitle: this.getUnitTitle(this.unitType),
          unitSurface: this.getUnitSurface(this.unitType),
          period: this.period,
          amount: response.amount,
          price: response.price,
          insurance: response.insurance,
          promo: this.promo,
          completed: true,
          dateFrom: this.dateFrom,
          humanDateFrom: this.humanDateFrom,
        });
      } catch (e) {
        if (e.response.status === 404) {
          this.invalidPromo = true;
          this.validate();
        } else {
          this.error = e.response.data.message;
        }
      }
      this.loading = false;
    },
    async next() {
      if (!this.value.completed) {
        await this.getPrice();
      }
      this.$emit('change');
    },
    getUnitTitle(type) {
      return this.unitTypes.find(unitType => unitType.unit_type === type)?.name;
    },
    getUnitSurface(type) {
      return this.unitTypes.find(unitType => unitType.unit_type === type)?.surface;
    },
  },
};
</script>
