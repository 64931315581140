<template>
  <v-form ref="form" @submit.stop.prevent="login" v-model="valid">
    <v-subheader>Авторизация</v-subheader>
    <v-text-field
        v-model="email"
        label="Логин (e-mail)"
        required
        :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
    ></v-text-field>
    <v-text-field
        v-model="password"
        :type="showPass ? 'text' : 'password'"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPass = !showPass"
        label="Пароль"
        required
        :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
    ></v-text-field>
    <alert-banner v-model="error"/>
    <v-divider></v-divider>
    <v-container class="px-0">
      <v-btn color="primary" outlined type="submit" :loading="loading" :disabled="loading">
        Войти
      </v-btn>
    </v-container>
    <v-container v-if="showLinks">
      <v-row>
        <v-col cols="12">
          <router-link :to="{name: 'ForgotPassword'}">Я не помню пароль</router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <a href="#" @click.stop.prevent="showCheckout">Я тут впервые и хочу арендовать бокс</a>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <a :href="`${PROFILE_DOMAIN}/forgot`">Я не помню пароль</a>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AlertBanner from "@/components/AlertBanner";

export default {
  name: "Login",
  components: {AlertBanner},
  props: {
    showLinks: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      loading: false,
      valid: true,
      showPass: false,
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
    }),
  },
  methods: {
    ...mapActions({
      apiLogin: "profile/login",
    }),
    showCheckout() {
      this.$emit('show-checkout');
    },
    async login() {
      this.valid = this.$refs.form.validate();
      this.loading = true;
      try {
        if (this.valid) {
          await this.apiLogin({
            email: this.email,
            password: this.password,
          });
          this.$emit("login");
        }
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>
