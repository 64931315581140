<template>
  <base-dialog
      :value="value"
      @input="$emit('input', $event)"
      :max-width="maxWidth"
      :no-full="noFull"
  >
    <template #activator="{ on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card class="d-flex flex-column" :min-height="minHeight" :max-height="maxHeight">
      <v-app-bar
          v-if="$slots.title || $slots.subtitle"
          flat
          class="flex-grow-0"
      >
        <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            icon
            @click="$emit('input', false)"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="title pl-0">
          <slot name="title"/>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            icon
            @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template #extension v-if="$slots.subtitle">
          <slot name="subtitle"/>
        </template>
      </v-app-bar>
      <v-card-text class="flex-grow-1 scrollable">
        <slot/>
      </v-card-text>
      <v-divider v-if="$slots.actions"/>
      <v-card-actions v-if="$slots.actions">
        <slot name="actions" :dialog="value"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>
<style scoped>
.scrollable {
  overflow-y: auto;
}
</style>
<script>
import BaseDialog from "@/components/BaseDialog";

export default {
  name: "DialogCard",
  components: {BaseDialog},
  props: {
    value: Boolean,
    noFull: Boolean,
    maxWidth: Number,
    minHeight: Number,
    maxHeight: Number,
    submit: Function,
  },
};
</script>
