import CONFIG from "@/config";
import api from "@/api/axiosFactory";

export default {
    async me({commit}) {
        let data = {};
        try {
            const user = await api({
                url: CONFIG.api.profile.me,
            });
            data = user.data;
        } catch {
            //
        }
        commit("setUser", data);
        return data;
    },
    async register({commit}, data) {
        const user = await api({
            url: CONFIG.api.profile.register,
            method: "post",
            data,
        });
        const token = await api({
            url: CONFIG.api.profile.admin,
            method: "post",
            data,
        });
        commit("setToken", token.data.token);
        return commit("setUser", user.data);
    },
    async login({commit}, data) {
        const token = await api({
            url: CONFIG.api.profile.admin,
            method: "post",
            data,
        });
        commit("setToken", token.data.token);
        const user = await api({
            url: CONFIG.api.profile.me,
        });
        commit("setUser", user.data);
        return user.data;
    },
    async token({commit}, data) {
        commit("setToken", data.token);
        const user = await api({
            url: CONFIG.api.profile.me,
        });
        commit("setUser", user.data);
        return user.data;
    },
    async logout({commit}) {
        commit("setToken", null);
        return commit("setUser", []);
    },
};
