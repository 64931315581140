<template>
  <v-stepper
      v-model="step"
      vertical
      class="elevation-0"
  >
    <v-stepper-step
        editable
        :complete="step > 1"
        step="1"
        edit-icon="$complete"
    >
      <div v-html="step > 1 ? `${unit.unitTitle}: ${format.toCurrency(unit.amount)}` : 'Выбор бокса'"></div>
    </v-stepper-step>
    <v-stepper-content
        step="1"
    >
      <unit-selector
          v-model="unit"
          @change="unitSelected"
          :init-facility="initFacility"
          :init-unit="initUnit"
      />
    </v-stepper-content>
    <v-stepper-step
        :editable="unit.completed && (user.isNew || canLogout)"
        :complete="step > 2"
        @click="unit.completed && user.completed && (user.isNew || canLogout) && unitSelected(false)"
        step="2"
        edit-icon="$complete"
    >
      {{ user.completed ? `${user.firstname || user.name || user.company} (${user.email})` : "Личные данные" }}
    </v-stepper-step>
    <v-stepper-content step="2">
      <user-selector
          v-model="user"
          ref="userSelector"
          @change="userSelected"
          @fail="step = 2"
          @reset="reset"
          :callback-handler="callback"
      />
    </v-stepper-content>
    <v-stepper-step
        :editable="unit.completed && user.completed"
        :step="3"
        @click="unit.completed && user.completed && $refs.userSelector.next()"
    >
      Оплата
    </v-stepper-step>
    <v-stepper-content
        :step="3">
      <v-container>
        <alfa-insurance
            v-if="unit.insurance"
            v-model="insurance"
            :label="unit.insurance"
        />
        <v-checkbox
            v-if="!unit.promo"
            v-model="recurring"
            label="Подключить автоплатеж"
        />
        <v-checkbox
            v-if="unit.promo"
            label="Подключить автоплатеж"
            disabled
        />
        <small v-if="!unit.promo" class="d-block mt-n3">Продлевается автоматически. Отменить можно в любой момент.</small>
        <small v-if="unit.promo" class="d-block mt-n3">Автоплатеж можно включить при оплате без промокода.</small>
        <div class="d-flex flex-column flex-sm-row mt-6 pay-buttons">
          <v-btn
              :disabled="payLoading || !(unit.completed && user.completed)"
              :loading="payLoading"
              color="primary"
              outlined
              class="mr-sm-4"
              @click="pay()"
              large
              :block="$vuetify.breakpoint.xsOnly">
            <v-icon left v-if="unit.amount">mdi-credit-card-outline</v-icon>
            {{ unitAmount ? `${format.toCurrency(unitAmount)}` : "Арендовать" }}
          </v-btn>
          <v-btn
              v-if="unit.amount"
              @click="pay('sbp')"
              :disabled="payLoading || !(unit.completed && user.completed)"
              :loading="payLoading"
              color="primary"
              outlined
              class="mt-4 mt-sm-0 mr-sm-4"
              large
              :block="$vuetify.breakpoint.xsOnly"
          >
            <v-img style="max-width: 16px" :src="`${PROFILE_DOMAIN}/img/sbp.svg`"/>
            {{ "Оплатить через СБП" }}
          </v-btn>
        </div>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>
<style>
.pay-buttons .google-pay-btn button, .pay-buttons .apay-button {
  height: 44px;
  width: 100%;
}
</style>
<style scoped>
.date-field {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 600px) {
  .date-field {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.v-card__text, .v-card__title {
  word-break: normal;
}

.mezzanine-informer-mobile {
  max-width: 100%;
}

.mezzanine-informer {
  width: 200px;
  height: auto;
}
</style>
<script>
import {mapActions} from "vuex";
import format from "@/lib/format";
import UnitSelector from "@/components/checkout/UnitSelector";
import UserSelector from "@/components/checkout/UserSelector";
import CustomerRepository from "@/repository/customer";
import AlfaInsurance from "./checkout/AlfaInsurance.vue";

export default {
  name: "Checkout",
  components: {
    AlfaInsurance,
    UserSelector,
    UnitSelector,
  },
  props: {
    canLogout: {
      type: Boolean,
      default: true,
    },
    initFacility: String,
    initUnit: String,
  },
  data() {
    return {
      /**/
      step: 1,
      error: "",
      payLoading: false,
      recurring: false,
      insurance: true,
      unit: {},
      user: {},
      format,
      reached: {},
      env: process.env,
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
  computed: {
    unitAmount() {
      return this.unit.amount + (this.insurance ? this.unit.insurance : 0);
    }
  },
  methods: {
    async unitSelected(skipOnLoggedIn = true) {
      this.reach(1);
      this.step = 2;
      if (skipOnLoggedIn && this.user.completed) {
        await this.userSelected();
      }
    },
    async userSelected() {
      try {
        await CustomerRepository.createLead({
          name: this.user.name,
          company: this.user.company,
          email: this.user.email,
          phone: this.user.phone,
          amount: this.unit.amount,
          period: this.unit.period,
          surface: this.unit.unitSurface,
        });
      } catch (e) {
        console.log(e)
      }
      this.reach(2);
      this.step = 3;
    },
    reach(step) {
      if (!this.reached[step]) {
        document.dispatchEvent(new CustomEvent("yandex-metrika-goal", {detail: `checkout-${step}`}));
        this.reached[step] = true;
      }
    },
    async leadCustomer() {
      if (!this.user.isNew) {
        return true;
      }
      if (this.user.completed) {
        await CustomerRepository.createLead({
          name: this.user.name,
          company: this.user.company,
          email: this.user.email,
          phone: this.user.phone,
          amount: this.unit.amount,
          period: this.unit.period,
          surface: this.unit.unitSurface,
        });
        return true;
      }
      return false;
    },
    async callback() {
      await CustomerRepository.sendRentRequest({
        user: `${this.user.company} (Контактное лицо: ${this.user.name})`,
        email: this.user.email,
        phone: this.user.phone,
        unit: this.unit.unitType,
        business: true,
        comment: `Заявка на аренду от юридического лица
          * склад ${this.unit.facilityCode}
          * c ${this.unit.humanDateFrom}
          * на срок ${this.unit.period}
          * промокод ${this.unit.promo || "не указан"}
          `,
        source: "checkout",
      });
    },
    async gPay(data) {
      try {
        await this.pay('gpay', data.paymentMethodData.tokenizationData.token);
      } catch {
        //
      }
    },
    async aPay(data) {
      return await this.pay('apay', data);
    },
    async pay(paymentMethod = null, paymentData = null) {
      const details = await this.payHandler(paymentMethod, paymentData);
      if (details) {
        return await this.postPaid(details);
      }
      throw new Error("Невозможно оформить заказ");
    },
    async appleValidator(url) {
      return await CustomerRepository.validateAppleSession({url});
    },
    async payHandler(paymentMethod = null, paymentData = null) {
      this.payLoading = true;
      this.error = "";
      try {
        if (this.user.isNew) {
          await this.signIn();
        }
        return await CustomerRepository.checkout({
          facilityCode: this.unit.facilityCode,
          unitType: this.unit.unitType,
          startDate: this.unit.dateFrom,
          period: this.unit.period,
          promoCode: this.unit.promo,
          recurring: this.recurring,
          useInsurance: this.unit.insurance >= 0 && this.insurance,
          paymentMethod,
          paymentData,
        });
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.payLoading = false;
    },
    async postPaid(details) {
      this.payLoading = true;
      if (details.url) {
        if (details.url_method === 'POST') {
          let form = document.createElement('form');
          form.action = details.url;
          form.method = details.url_method;
          Object.keys(details.url_params).forEach(key => {
            let input = document.createElement('input');
            input.type = 'text';
            input.name = key;
            input.value = details.url_params[key];
            form.appendChild(input);
          });
          document.body.appendChild(form);
          form.submit();
        } else {
          document.location = details.url;
        }
      } else {
        this.reset();
      }
      this.payLoading = false;
      this.$emit('paid');
    },
    async signIn() {
      const data = {
        facilityCode: this.unit.facilityCode,
        email: this.user.email,
        phone: this.user.phone,
        password: this.user.password,
        password_confirmation: this.user.confirmPassword,
        marketing: this.user.marketing,
      };

      if (this.user.name) {
        data.firstname = this.user.name;
        data.passport_serial = this.user.passportSerial;
        data.passport_number = this.user.passportNumber;
      } else {
        data.company = this.user.company;
        data.inn = this.user.inn;
      }
      return await this.register(data);
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
      this.$emit("reset");
    },
    ...mapActions({
      register: "profile/register",
    }),
  },
};
</script>
