export default {
    parse(iso) {
        return new Date(Date.parse(iso));
    },
    toHuman(date, showTime = false) {
        return date[showTime ? 'toLocaleString' : 'toLocaleDateString']("ru")
    },
    isoToHuman(iso, showTime = false) {
        return this.toHuman(this.parse(iso), showTime);
    },
};
