<template>
  <div>
    <div v-show="!user.email">
      <a v-if="!showLogin" href="#" @click.prevent.stop="showLogin = true" v-html="'У меня уже есть аккаунт &gt;&gt;'"/>
      <a v-if="showLogin" href="#" @click.prevent.stop="showLogin = false" v-html="'&lt;&lt; Создать аккаунт'"/>
      <v-form ref="registration" v-model="valid" v-if="!showLogin">
        <div>
          <v-radio-group
              :row="!$vuetify.breakpoint.xsOnly"
              :column="$vuetify.breakpoint.xsOnly"
              v-model="customerType"
              mandatory
          >
            <v-radio label="Физическое лицо" value="person"></v-radio>
            <v-radio label="Юридическое лицо" value="company" readonly @click.stop="companyDialog = true;"></v-radio>
          </v-radio-group>
        </div>
        <div v-if="customerType === 'person'">
          <v-text-field
              v-model="name"
              label="Фамилия, имя, отчество"
              required
              @change="getUser"
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле', v => v && v.indexOf(' ') > 0 || 'Укажите имя и фамилию']"
          ></v-text-field>
          <div>
            <v-subheader>Паспорт</v-subheader>
            <v-text-field
                class="col-4 d-inline-block pa-0"
                v-model="passportSerial"
                label="Серия"
                @change="getUser"
                required
                :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
            />
            <v-text-field
                class="col-8 d-inline-block pa-0"
                v-model="passportNumber"
                label="Номер"
                @change="getUser"
                required
                :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
            />
          </div>
        </div>
        <div v-if="customerType === 'company'">
          <v-text-field
              v-model="company"
              label="Название компании"
              @change="getUser"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          ></v-text-field>
          <v-text-field
              v-model="name"
              label="Контактное имя"
              @change="getUser"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          ></v-text-field>
        </div>
        <v-text-field-phonemask
            v-model="phone"
            label="Телефон"
            v-bind:options="{
                  inputMask: '+# (###) ###–##–##',
                  outputMask: '###########',
                  countryCode: '7',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
            type="tel"
            @change="getUser"
            required
            :rules="[v => !!v || v && !!v.trim() || v && !!v.trim() || 'Обязательное поле', v => v && v.length === 18 || 'Неверный формат']"
        />
        <v-text-field
            v-model="email"
            type="email"
            label="e-mail"
            @change="getUser"
            required
            :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
        ></v-text-field>
        <v-text-field
            v-if="customerType === 'person'"
            v-model="password"
            @change="getUser"
            label="Пароль"
            type="password"
            :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
            required
        ></v-text-field>
        <v-text-field
            v-if="customerType === 'person'"
            v-model="confirmPassword"
            label="Подтверждение пароля"
            @change="getUser"
            type="password"
            :rules="[v => !!v && !!v.trim() || 'Обязательное поле', v => v === this.password || 'Пароли не совпадают']"
            required
        ></v-text-field>
        <v-checkbox
            v-if="customerType === 'person'"
            v-model="marketing"
            @change="getUser"
            class="pl-2"
        >
          <template #label>
            <p>Я <a :href="`${PROFILE_DOMAIN}/img/docs/statement_of_consent_adv_online.pdf`" target="_blank" @click.stop>согласен</a> на получение сообщений рекламного характера посредством sms и e-mail</p>
          </template>
        </v-checkbox>
        <v-checkbox
            v-if="customerType === 'person'"
            required
            @change="getUser"
            :rules="[v => !!v || 'Необходимо согласие']"
        >
          <template #label>
            <p>Я <a :href="`${PROFILE_DOMAIN}/img/docs/statement_of_consent_account.pdf`" target="_blank" @click.stop>согласен</a> на обработку моих персональных данных в целях заключения договора</p>
          </template>
        </v-checkbox>
      </v-form>
      <login v-if="showLogin" @login="next()"></login>
      <div v-if="!showLogin" class="d-flex">
        <v-btn
            @click="next()"
            text
            color="primary"
            :disabled="!valid"
            :loading="loading"
            class="px-0"
        >
          Далее >>
        </v-btn>
      </div>
    </div>
    <div v-if="user.email && canLogout">
      <div class="d-flex">
        <v-btn
            @click="callLogout"
            outlined
            color="primary"
            :disabled="loading"
            :loading="loading"
        >
          Сменить пользователя
        </v-btn>
      </div>
    </div>
    <dialog-card
        max-width="560px"
        v-model="companyDialog"
    >
      <template #title>
        Внимание
      </template>
      <p>Для оформление договора на юридическое лицо, пожалуйста, свяжитесь с нами:</p>
      <ol>
        <li>через <a href="https://wa.me/78122070007">WhatsApp</a> или <a href="https://t.me/Prostor_Self_Storage_Bot">Telegram</a>;</li>
        <li>по телефону <a href="tel://+78122070007">+7&nbsp;(812)&nbsp;207&ndash;00&ndash;07</a>.</li>
      </ol>

      <template #actions>
        <v-btn
            color="primary"
            text
            large
            @click="companyDialog = false"
        >
          Хорошо
        </v-btn>
      </template>
    </dialog-card>
    <alert-banner v-model="error"/>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DialogCard from "@/components/DialogCard";
import Login from "@/components/Login";
import AlertBanner from "@/components/AlertBanner";
import CustomerRepository from "@/repository/customer";

export default {
  name: "UserSelector",
  components: {AlertBanner, Login, DialogCard},
  props: {
    value: Object,
    canLogout: {
      type: Boolean,
      default: true,
    },
    unitSurface: String,
    amount: Number,
    callbackHandler: Function,
  },
  data() {
    return {
      valid: false,
      showLogin: false,
      customerType: "person",
      name: "",
      passportSerial: "",
      passportNumber: "",
      company: "",
      inn: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      marketing: false,
      loading: false,
      companyDialog: false,
      forceCompanyCheckout: false,
      error: "",
      MAIN_DOMAIN: process.env.VUE_APP_MAIN_DOMAIN,
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
  async mounted() {
    try {
      await this.me();
    } catch {
      //
    }
    if (this.user.email) {
      this.getUser();
    } else {
      this.$emit('input', {completed: false, isNew: true});
    }
  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
    }),
  },
  methods: {
    validate() {
      this.valid = this.valid && this.$refs.registration.validate();
      this.$emit('input', {completed: false});
      return this.valid;
    },
    getUser() {
      if (!this.user.email && !this.validate()) {
        return false;
      }
      const user = this.user.email ? this.user : {
        name: this.name,
        passportSerial: this.customerType === 'person' ? this.passportSerial : null,
        passportNumber: this.customerType === 'person' ? this.passportNumber : null,
        company: this.customerType === 'company' ? this.company : null,
        inn: this.customerType === 'company' ? this.inn : null,
        phone: this.phone,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        marketing: this.marketing,
        isNew: true,
      };
      user.completed = true;
      this.$emit('input', user);
      return true;
    },
    async next(forceCompanyCheckout = false) {
      this.error = "";
      if (forceCompanyCheckout) {
        this.forceCompanyCheckout = forceCompanyCheckout;
      }
      if (!this.value.completed && !await this.getUser()) {
        this.$emit('fail');
        return;
      }
      if (this.value.company && !(forceCompanyCheckout || this.forceCompanyCheckout)) {
        this.companyDialog = true;
        this.$emit('fail');
        return;
      }
      try {
        await CustomerRepository.checkCustomer({
          email: this.email,
          phone: this.phone,
        })
      } catch (e) {
        this.error = e.response.data.message;
        if (e.response.status === 400) {
          this.showLogin = true;
        }
        this.$emit('fail');
        return;
      }
      this.$emit('change');
    },
    async callLogout() {
      this.loading = true;
      await this.logout();
      this.showLogin = false;
      this.$emit('input', {completed: false});
      this.loading = false;
    },
    async callback() {
      this.loading = true;
      await this.callbackHandler();
      this.confirmCallbackDialog = true;
      this.companyDialog = false;
      this.loading = false;
    },
    ...mapActions({
      me: "profile/me",
      logout: "profile/logout",
      free: "profile/free",
    }),
  },
};
</script>
