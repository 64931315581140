import CONFIG from "@/config";
import api from "@/api/axiosFactory";

export default {
    async forgotPassword(data) {
        const response = await api({
            url: CONFIG.api.profile.forgot,
            method: "post",
            data,
        });
        return response.data;
    },
    async updatePassword(data) {
        const response = await api({
            url: CONFIG.api.profile.password,
            method: "post",
            data,
        });
        return response.data;

    },
    async resetPassword(data) {
        const response = await api({
            url: CONFIG.api.profile.reset,
            method: "post",
            data,
        });
        return response.data;
    },
    async restoreCustomer(data) {
        const response = await api({
            url: CONFIG.api.profile.restore,
            method: "post",
            data,
        });
        return response.data;
    },
    async sendPin(facilityCode) {
        const result = await api({
            url: `${CONFIG.api.profile.pin}/${facilityCode}`,
        });
        return result.data;
    },
    async placeInvoice({invoice, data}) {
        const payment = await api({
            url: `${CONFIG.api.payment.pay}/${invoice}`,
            method: "post",
            data,
        });
        return payment.data;
    },
    async removeSubscription(invoiceId) {
        const payment = await api({
            url: `${CONFIG.api.profile.subscriptions}/${invoiceId}`,
            method: "delete",
        });
        return payment.data;
    },
    async removeUnit(unit) {
        await api({
            url: `${CONFIG.api.profile.units}/${unit}`,
            method: "delete",
        });
    },
    async requestCustomInvoice(data) {
        const request = await api({
            url: CONFIG.api.profile.customInvoice,
            method: "post",
            data,
        });
        return request.data;
    },
    async sendRentRequest(data) {
        const request = await api({
            url: CONFIG.api.profile.rentRequest,
            method: "post",
            data,
        });
        return request.data;
    },

    async pay(url) {
        const payment = await api({
            url: url,
            method: "post",
        });
        return payment.data;
    },
    async createLead(data) {
        const request = await api({
            url: CONFIG.api.profile.lead,
            method: "post",
            data,
        });
        return request.data;
    },
    async priceRequest(data) {
        const request = await api({
            url: CONFIG.api.profile.price,
            method: "post",
            data,
        });
        return request.data;
    },
    async checkout(data) {
        const request = await api({
            url: CONFIG.api.profile.checkout,
            method: "post",
            data,
        });
        return request.data;
    },
    async validateAppleSession(data) {
        const request = await api({
            url: CONFIG.api.profile.applePayValidate,
            method: "post",
            data,
        });
        return request.data;
    },
    async download(data) {
        const request = await api({
            url: data.url,
            method: "get",
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(request.data);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
};
