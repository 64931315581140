<template>
  <v-checkbox
      v-model="value"
      @change="$emit('input', $event)"
      color="alfa"
  >
    <template #label>
      <img :src="`${PROFILE_DOMAIN}/img/alfa-insurance.svg`" alt="Альфа Страхование" title="Альфа Страхование" class="pa-0 pr-4 pb-1" style="height: 28px">
      {{ format.toCurrency(label) }}
      <dialog-card
          v-model="dialog"
          max-width="650px"
      >
        <template #title>
          <span class="d-none d-sm-inline-block">Страхование имущества <span class="d-none d-md-inline-block">клиентов</span></span>
          <img :src="`${PROFILE_DOMAIN}/img/alfa-insurance.svg`" alt="Альфа Страхование" title="Альфа Страхование" style="height: 32px" class="pl-sm-6 pl-md-12">
        </template>
        <div class="pl-12 mb-6 mt-8 text-h5 insurance-logo">Страхование имущества клиентов от пожара, потопа, стихийных бедствий и&nbsp;т.&nbsp;д.</div>
        <div class="pl-12">Это будет дополнительная плата согласно тарифам и максимальная выплата премии исходя из размера бокса</div>
        <template #actions>
          <v-btn
              color="primary"
              outlined
              @click="dialog = false"
          >
            Понятно
          </v-btn>
        </template>
        <template #activator="{on}">
          <v-btn icon v-on="on">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
      </dialog-card>
    </template>
  </v-checkbox>
</template>
<style scoped>
.insurance-logo {
  background: url(/img/insurance-shield.svg) no-repeat left center/40px;
}
</style>
<script>
import format from "@/lib/format";
import DialogCard from "../DialogCard.vue";

export default {
  name: "AlfaInsurance",
  components: {DialogCard},
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    }
  },
  data() {
    return {
      dialog: false,
      format,
      PROFILE_DOMAIN: process.env.VUE_APP_PROFILE_DOMAIN,
    };
  },
};
</script>
