<template>
  <base-dialog
      :value="value"
      max-width="700px"
      @input="$emit('input', $event)"
  >
    <template #activator="{ on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card class="d-flex flex-column" flat>
      <v-app-bar
          flat
          class="flex-grow-0"
      >
        <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            icon
            @click="$emit('input', false)"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="title pl-0">
          Аренда
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            icon
            @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text class="flex-grow-1 scrollable pa-0">
        <checkout
            :can-logout="canLogout"
            @reset="$emit('input', false)"
            @paid="$emit('paid')"
            :init-facility="facility"
            :init-unit="unit"
        />
      </v-card-text>
    </v-card>
  </base-dialog>
</template>
<style scoped>
.scrollable {
  overflow-y: auto;
}
</style>
<script>
import Checkout from "@/components/Checkout";
import BaseDialog from "@/components/BaseDialog";

export default {
  name: "CheckoutDialog",
  components: {
    BaseDialog,
    Checkout,
  },
  props: {
    canLogout: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    facility: {
      type: String,
      default: ""
    },
    unit: {
      type: String,
      default: ""
    }
  },
};
</script>
